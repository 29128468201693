
html {
  font-size: 15px; }

body {
  /* font-size: */
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  > .columns {
    display: flex;
    margin: 0;
    height: 100%;
    min-height: 100%;
    background-color: $white;
    > .column {
      height: 100%;
      min-height: 100%;
      max-width: 100%;
      overflow-y: auto;
      &:first-child {
        display: block;
        flex: none;
        width: 16.6666666667%; } } } }

#doc-menu {
  background-color: $white !important;
  box-shadow: 0 2px 8px rgba(14, 20, 26, 0.1);
  min-height: 100vh; }

.mobile-burger {
  @include hamburger(2rem);
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 50;
  background-color: $light;
  padding: 1rem;
  border-radius: $radius; }

p {
  margin-bottom: 1em; }

.card {
  border-radius: $radius; }

.has-text-weight-light {
  font-weight: 100; }

.is-logged-out:before {
  content: "";
  display: block;
  position: absolute;
  top: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, .8);
  z-index: 9; }
