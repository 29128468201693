.is-fullheight {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: stretch; }

.is-fullwidth {
  width: 100%; }

.has-background-transparent {
    background-color: transparent; }

.is-marginless-top {
  margin-top: 0; }
.is-marginless-right {
  margin-right: 0; }
.is-marginless-bottom {
  margin-bottom: 0; }
.is-marginless-left {
  margin-left: 0; }
.is-marginless-horizontal {
  margin-left: 0;
  margin-right: 0; }
.is-marginless-vertical {
  margin-top: 0;
  margin-bottom: 0; }

.is-paddingless-top {
  padding-top: 0; }
.is-paddingless-right {
  padding-right: 0; }
.is-paddingless-bottom {
  padding-bottom: 0; }
.is-paddingless-left {
  padding-left: 0; }
.is-paddingless-horizontal {
  padding-left: 0;
  padding-right: 0; }
.is-paddingless-vertical {
  padding-top: 0;
  padding-bottom: 0; }

*:not(td):not(.table) {
  &.has-content-vcentered {
    display: flex;
    align-items: center; }
  &.has-content-hcentered {
    display: flex;
    justify-content: center; }
  &.has-content-hcentered {
    display: flex;
    align-items: center;
    justify-content: center; }
  &.has-content-left {
    display: flex;
    justify-content: flex-start; }
  &.has-content-right {
    display: flex;
    justify-content: flex-end; }
  &.has-content-top {
    display: flex;
    align-items: flex-start; }
  &.has-content-bottom {
    display: flex;
    align-items: flex-end; } }
