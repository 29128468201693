.highlight {
  position: relative;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  background-color: $light;
  border-radius: 4px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $grey-dark;
  pre {
    background-color: transparent;
    font-size: .75em;
    line-height: 1.375;
    overflow: auto;
    max-width: 100%; } }

pre code {
  font-size: 1.1em; }
