.file-tree {
  background-color: $light;
  margin: 24px 0;
  padding: 20px 10px 10px;
  ul {
    list-style: none;
    padding-left: 25px;
    display: none; }
  > ul {
    display: block; }
  li {
    padding: 0;
    line-height: 2rem; }
  i {
    font-style: normal;
    font-size: .85rem;
    color: #b4b7b8;
    cursor: default;
    transition: color 0.4s ease;
    &:before {
      content: ' - ';
      color: black; } }
  li:hover > i {
    color: #697376; }
  .is-folder {
    > span {
      font-weight: 500;
      font-size: 15px;
      display: inline-block;
      min-width: 120px;
      &:before {
        content: "\f07b";
        font-family: 'Font Awesome 5 Free';
        margin-right: 8px;
        color: #8b949e;
        display: inline-block;
        width: 18px; } }
    &.is-open > {
      span:before {
        content: ""; }
      ul {
        display: block; } } }
  .is-file > span {
    display: inline-block;
    min-width: 120px;
    &:before {
      content: "\f15b";
      font-family: 'Font Awesome 5 Free';
      margin-right: 8px;
      color: #bec7d2;
      display: inline-block;
      width: 18px; } } }
