.anchor {
  position: relative;
  display: block;
  font-weight: 500;
  padding-top: 2rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  border-top: .1rem solid $light;
  &::after {
    content: '#';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: $primary;
    position: relative;
    top: -.1rem; }
  &:first-child {
    margin-top: 0;
    border-top: none; } }
