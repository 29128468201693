@import '~bulma/sass/utilities/initial-variables';

@font-face {
    font-weight: normal;
    font-family: "AmpleSoft";
    src: url("../fonts/AmpleSoft.otf"); }

@font-face {
    font-weight: 300;
    font-family: "AmpleSoft";
    src: url("../fonts/AmpleSoft Light.otf"); }

@font-face {
    font-weight: 100;
    font-family: "AmpleSoft", Arial, Helvetiva, sans-serif;
    src: url("../fonts/AmpleSoft ExtraLight.otf"); }

@font-face {
    font-family: "Proxima Nova";
    font-weight: normal;
    src: url("../fonts/ProximaNova-Regular.otf"); }

@font-face {
    font-family: "Proxima Nova";
    font-weight: lighter;
    src: url("../fonts/ProximaNova-Light.otf"); }

@font-face {
    font-family: "Proxima Nova";
    font-weight: bold;
    src: url("../fonts/ProximaNova-Semibold.otf"); }



$primary: #0BB4F5;
$dark: #2E3243;

$column-gap: 2.5rem;

// Typography
$family-primary: "Proxima Nova", Arial, Helvetiva, sans-serif !default;
$family-title: "AmpleSoft", Arial, Helvetiva, sans-serif !default;

$navbar-item-img-max-height: 4rem;
$navbar-item-active-background-color: transparent;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $primary;
$navbar-item-color: white;

$navbar-height: 6rem;

$input-box-shadow-size: none;
$input-box-shadow-color: none;
