@charset "utf-8";

/**
 * -----------------------------------------
 *  MAKE TEXT RESPONSIVE
 * -----------------------------------------
 */
@media (max-width: 672px) {
  root: {
    font-size: 90%; } }

/**
 * -----------------------------------------
 *  IMPORT UTILITIES PARTIALS
 * -----------------------------------------
 */
@import "_mixins.sass";
@import "_helpers.sass";

/**
 * -----------------------------------------
 *  IMPORT BULMA's CUSTOMIZATION
 * -----------------------------------------
 */
@import "../bulma-custom/_custom.sass";

/**
 * -----------------------------------------
 *  IMPORT THEME SPECIFIC SASS
 * -----------------------------------------
 */
@import "layout/video.sass";
@import "anchor.sass";
@import "custom.sass";
@import "file-tree.sass";
@import "highlight.sass";
@import "menu.sass";
@import "steps.sass";
