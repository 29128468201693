
@import './0-variables.sass';
@import '~bulma/bulma';
@import '~bulma/sass/utilities/mixins.sass';

body {
    color: #2E3243;
    overflow: auto !important;
    font-size: 19px; }
p {
    color: #37383C; }

.navbar {
    z-index: 1;
    a.navbar-item {
        transition: .3s;
        &:hover {
            opacity: 0.7;
            background-color: transparent !important; }
        .button {
            font-size: 20px !important;
            height: 40px;
            width: 120px;
            font-size: 13px !important;
            font-weight: bold;
            &:hover img {
                filter: brightness(5%); }
            img {
                margin-right: 10px; } } } }


.menu-trigger {
    display: block;
    height: 24px;
    width: 40px;
    position: fixed;
    right: 35px;
    top: 35px;
    cursor: pointer;
    z-index: 99;
    &.is-dark {
        &::after, &::before, span {
            background: $dark; } }
    &::after, &::before, span {
        content: "";
        position: absolute;
        height: 4px;
        width: 100%;
        background: white;
        transition: .1s; }
    &::after {
        top: 0; }
    &::before {
        bottom: 0; }
    span {
        top: calc(50% - 2.5px); } }

#menu-checkbox {
    display: none;
    height: 0;
    width: 0;
    &:checked + .menu-trigger {
        &::after {
            transform: rotate(-45deg);
            top: calc(50% - 2.5px);
            background: black !important; }
        &::before {
            transform: rotate(45deg);
            top: calc(50% - 2.5px);
            background: black !important; }
        span {
            display: none; } }
    &:checked ~ .menu-trigger-content {
        right: 0 !important; } }

.menu-trigger-content {
    height: 100vh;
    width: 500px;
    background: white;
    right: -500px;
    top: 0;
    position: fixed;
    z-index: 8;
    transition: .4s;
    &:hover {
        opacity: 1; }
    .content {
        width: 100%;
        padding-top: 80px;
        padding-right: 35px;
        text-align: right;
        a {
            color: black !important;
            font-size: 26px;
            display: block;
            margin: 20px 0;
            letter-spacing: -1px;
            .is-active {
                font-width: 700; } } } }




.hero,.section {
    overflow: hidden;
    .title.is-1 {
        font-weight: 100; } }

// sections
#Home {
    background: #2E3243 url('../images/1x/bg.png') fixed;
    background-blend-mode: overlay;
    .columns {
        @include touch {
            text-align: right !important; } }
    .title {
        font-size: 66px;
        font-weight: 100;
        @include mobile {
            font-size: 40px; } }

    .line {
        height: 2px;
        background: $primary;
        display: block;
        width: 50px;
        margin-bottom: 10px;
        display: inline-block;
        @include touch {
            text-align: right; } }

    .subtitle {
        font-size: 28px;
        font-weight: 300;
        @include mobile {
            font-size: 23px; } }
    .button {
        border: 1px solid #FAFAFA;
        color: #fafafa;
        background: transparent;
        border-radius: 40px;
        height: 60px;
        padding: 20px 40px;
        transition: .3s;
        .icon {
            margin-right: 10px; }
        &:hover {
            transform: scale(1.05); } }
    .more {
        position: absolute;
        animation-name: setinha;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.925, -0.405, 0.490, 1.335);
        bottom: 50px;
        left: calc(50% - 15px);
        height: 30px;
        width: 30px;
        background: url('../images/SVG/setinha.svg') center center / contain no-repeat;
        @include touch {
            bottom: 20px; } }


    @keyframes setinha {
        0%,100% {
            transform: translateY(0); }
        50% {
            transform: translateY(15px); } } }



#Services {
    p {
        font-weight: 500;
        font-size: 17px; }
    .image {
        width: 90px; } }


#Ecommerce {
    background: #FAFAFA;
    position: relative;
    overflow: hidden;
    &:after,&:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 600px;
        z-index: 0; }
    &:after {
        left: -350px;
        background: url('../images/1x/bg-ecommerce.png') center left / contain no-repeat; }
    &:before {
        right: -350px;
        background: url('../images/1x/bg-ecommerce-2.png') center right / contain no-repeat; }
    * {
        z-index: 1; }
    .title.is-1 {
        font-weight: 100; }
    .platform {
        background: white;
        padding: 20px 30px;
        border-left: 2px solid #000;
        margin: 20px 0;
        width: 100%;
        text-align: center;
        transition: .3s;
        img {
            width: 200px; }
        &:hover {
            background: $dark;
            img {
                filter: invert(100%); } } } }

#Documentation {
    background: #2D364A url('../images/1x/bg-documentation.png') center center / cover no-repeat fixed;
    background-blend-mode: overlay;
    p {
        max-width: 70%;
        margin: 0 auto;
        font-size: 30px;
        color: #FAFAFA; }
    .button {
        border: 1px solid #FAFAFA;
        background: none;
        color: #FAFAFA;
        padding: 30px 50px;
        transition: .3s;
        &:hover {
            opacity: .6; } } }
#Help {
    h1.is-1 {
        font-size: 67px; }
    .button {
        border: 1px solid $dark;
        background: none;
        padding: 30px 50px;
        transition: .3s;
        margin-bottom: 10px;
        font-size: 20px;
        width: 300px;
        text-align: left !important;
        justify-content: flex-start;
        &:hover {
            opacity: .6; }

        .icon {
            margin-right: 15px; } } }

#Footer {
    span {
        color: $dark; } }
